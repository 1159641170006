import { createTheme } from '@mui/material/styles'
import common from '@mui/material/colors/common'

const styleGuide = {
  backgroundDark: '#121212',
  text: '#121212',
  textSecondary: '#757575',
  spacertop: '80px'
}

const brandColor = '#c7a17a'

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: brandColor,
      dark: brandColor
    },
    secondary: {
      main: '#838383',
      dark: '#838383'
    }
  },
  typography: {
    fontFamily: 'calluna-sans',
    h1: {
      fontFamily: 'proxima-nova',
      fontSize: '4rem',
      fontWeight: 900,
      letterSpacing: '1.5px'
    },
    h2: {
      fontFamily: 'proxima-nova',
      fontSize: '3rem',
      fontWeight: 900,
      letterSpacing: '1.5px'
    },
    h3: {
      fontFamily: 'proxima-nova',
      fontSize: '3rem',
      fontWeight: 900,
      letterSpacing: '1.5px'
    },
    h4: {
      fontFamily: 'proxima-nova',
      fontSize: '2.5rem',
      fontWeight: 900,
      letterSpacing: '1.5px'
    },
    h5: {
      fontFamily: 'proxima-nova',
      fontSize: '2.2rem',
      fontWeight: 900,
      letterSpacing: '1.5px'
    },
    h6: {
      fontFamily: 'proxima-nova',
      fontSize: '2rem',
      fontWeight: 900,
      letterSpacing: '1.5px'
    }
  }
})

theme.components = {
  MuiButton: {
    styleOverrides: {
      containedPrimary: {
        background: 'linear-gradient(45deg, #c7a17a 80%, #06d6a0 20%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(6, 214, 160, .3)'
      }
    }
  },
  MuiInput: {
    underline: {
      '&:after': {
        borderBottom: `2px solid #06d6a0`,
      }
    }
  },
  MuiFormLabel: {
    root: {
      '&$focused': {
        color: '#06d6a0'
      }
    }
  }
}

const loginStyle = {
  paddingTop: styleGuide.spacertop,
  paddingBottom: theme.spacing(2),
  color: common.white
}

const paperStyle = {
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2)
}

const dividerStyle = {
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4)
}

const noTxtDeco = {
  textDecoration: 'none'
}

const extLink = {
  color: styleGuide.text
}

const fullWidth = {
  width: '100%'
}

const gridWrapPaper = {
  margin: '24px'
}

const gridItem = {
  marginBottom: theme.spacing(),
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  padding: theme.spacing(3),
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}

const fabProgress = {
  position: 'absolute',
  top: -6,
  left: -6,
  zIndex: 1
}

const gridSpacer = {
  paddingTop: theme.spacing(),
  paddingBottom: theme.spacing()
}

const loginButton = {
  width: '100%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing()
}


const lockIcon = {
  width: theme.spacing(2),
  height: theme.spacing(2)
}

const colorFormat = {
  display: 'block',
  height: '100%',
  textAlign: 'center'
}

const gridWrap = {
  flewGrow: 1
}

const dashTable = {
  marginBottom: theme.spacing(3)
}

const sigCanvas = {
  border: '1px solid #525252',
  borderRadius: 4,
  display: 'block',
  width: '100%',
  maxWidth: '400px',
  height: 180,
  margin: '0 auto'
}

const sutroFont = {
  fontFamily: 'sutro !important',
  fontWeight: '500 !important'
}

const menuHeaderChoiceSub = {
  marginTop: '-12px !important',
  color: 'black'
}

const menuHeader = {
  ...sutroFont,
  color: brandColor,
  borderBottom: '2px solid black',
  fontSize: '1.75rem !important',
  lineHeight: '1.5 !important'
}

const menuHeaderTv = {
  ...menuHeader,
  fontSize: '2.8rem !important',
  lineHeight: '1.7 !important'
}

const menuHeaderTvHig = {
  ...menuHeaderTv,
  backgroundImage: 'url("//cdn.higcsc.com/menu/badges/by-hig.png")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '115px 48px',
  backgroundPosition: 'right 20px'
}

const menuHeaderHig = {
  ...menuHeader,
  backgroundImage: 'url("//cdn.higcsc.com/menu/badges/by-hig.png")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '115px 48px',
  backgroundPosition: 'right -3px'
}

const menuHeaderCart = {
  ...menuHeaderTv,
  fontSize: '1.6rem !important'
}

const menuHeaderCartChoiceSub = {
  color: brandColor,
  marginTop: '-12px !important'
}

const menuHeaderCartHig = {
  ...menuHeaderCart,
  backgroundImage: 'url("//cdn.higcsc.com/menu/badges/by-hig.png")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '86px 35px',
  backgroundPosition: 'right 9px'
}

const menuListHeader = {
  display:'inline-block !important',
  color: 'black !important',
  fontWeight: 'bold !important',
  textTransform:'uppercase !important',
  overflow:'hidden !important',
  whiteSpace:'nowrap !important',
  textOverflow:'ellipsis !important',
  maxWidth: '85% !important',
  lineHeight: '1 !important',
  fontSize: '1.3rem !important',
}

const menuListHeaderTv = {
  ...menuListHeader,
  fontSize: '1.6rem !important',
}

const menuListHeaderSmal = {
  float:'right !important',
  color: 'black !important',
  lineHeight: '1 !important',
  fontSize: '1.1rem !important',
  position: 'absolute',
  right: '3px',
  top: '18px'
}

const menuListHeaderSmalTv = {
  ...menuListHeaderSmal,
  fontSize: '1.3rem !important'
}

const menuListSecCart = {
  color:'black !important',
  lineHeight: '1.2 !important',
  fontSize: '0.8rem !important',
  maxWidth: '188px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}

const menuListSec = {
  color:'black !important',
  lineHeight: '1.2 !important',
  fontSize: '1rem !important',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
}

const menuListSecTv = {
  ...menuListSec,
  fontSize: '1.2rem !important'
}

const menuDivider = {
  border: '1px solid black !important'
}

const menuDividerSmal = {
  border: '0.5px solid black !important'
}

const higBotDivider = {
  marginTop: '80px',
  display: 'block',
  width: '100%'
}

const higTopDivider = {
  display: 'block',
  width: '100%',
  marginBottom: '80px'
}

const cartDivider = {
  width: '100%',
  display: 'block',
  marginBottom: 25
}

const cartAdWrap = {
  margin: '70px 25px 0px 49px'
}

const cartAdHeadline = {
  color:'black',
  fontFamily: 'sutro !important',
  fontSize: '30px !important'
}

const cartAdSubline = {
  color: brandColor,
  fontFamily: 'calluna-sans !important',
  fontSize: '18px !important',
  fontWeight: 'normal !important',
  paddingTop: 10
}

const cartAdVideo = {
  boxShadow: 'rgb(38, 57, 77) 0px 20px 30px -10px',
  borderRadius: 6
}

const menuWhiteBg = {
  height: '100vh !important',
  background: 'url(//cdn.higcsc.com/bg-cover.jpg) no-repeat center center fixed !important',
  backgroundSize: 'cover !important'
}

const menuWhiteLogo = {
  margin: '0 auto',
  display: 'block',
  top: '35%',
  position: 'relative'
}

const menuList = {
  padding: '0 !important'
}

const menuListChoice = {
  ...menuList,
  backgroundColor: brandColor

}

const menuListHighlight = {
  ...menuList,
  backgroundImage: 'url("//cdn.higcsc.com/menu/badges/new-badge.png")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '80px 50px',
  backgroundPosition: 'right 20px'
}

const menuListHighlightCart = {
  ...menuListHighlight,
  backgroundSize: '52px 32px',
  backgroundPosition: 'right 33px'
}

const menuListHighlightCartMenu = {
  ...menuListHighlight,
  backgroundSize: '47px 29px',
  backgroundPosition: 'right 42px'
}

const menuListHighlightSpannabis = {
  ...menuListHighlight,
  backgroundImage: 'url("//cdn.higcsc.com/menu/badges/top-badge.png")'
}

const menuListHighlightSpannabisCart = {
  ...menuListHighlightSpannabis,
  backgroundSize: '52px 32px',
  backgroundPosition: 'right 33px'
}

const menuListText = {
  margin: '12px 0 !important'
}

const menuListTextTv = {
  margin: '18px 0 !important'
}

const menuListMenuScreen = {
  margin: '16px 0 !important',
  textAlign: 'center'
}

const menuListMenuScreenCart = {
  margin: '14px 0 !important',
  textAlign: 'center'
}

const showCaseListHeader = {
  display:'inline-block !important',
  color: 'black !important',
  fontWeight: 'bold !important',
  textTransform:'uppercase !important',
  fontSize: '1rem !important',
  overflow:'hidden !important',
  whiteSpace:'nowrap !important',
  textOverflow:'ellipsis !important',
  maxWidth: '86% !important',
  lineHeight: '1 !important'
}

const showCaseListType = {
  color:'black !important',
  lineHeight: '1 !important',
  fontSize: '1.2rem !important',
  fontFamily: 'calluna-sans !important',
  fontWeight: 'normal !important'
}

const showCaseListTypeBig = {
  color: 'black !important',
  fontFamily: 'sutro !important',
  fontSize: '1.8rem !important',
  letterSpacing: 0,
  margin: '10px 0 !important'
}

const showCaseItemName = {
  fontFamily: 'sutro !important',
  color: '#c7a17a !important',
  fontSize: '2.85em !important',
  padding: 20
}

const showCaseItemParents = {
  fontFamily: 'sutro !important',
  color: 'black !important',
  fontSize: '1.2rem !important'
}

const showCaseGreeting = {
  fontFamily: 'sutro !important',
  color: '#c7a17a !important'
}

const showCaseName = {
  fontFamily: 'sutro !important',
  fontSize: '1.8 rem !important',
  padding: '20px 0',
  color: 'black !important'
}

const showCaseBalance = {
  fontWeight: 'bold !important',
  textTransform:'uppercase !important',
  fontSize: '1rem !important',
  lineHeight: '1 !important',
  color: '#fff !important',
  background: 'black',
  borderRadius: '25px 0px',
  padding: '5px 32px'
}

const cartMemberName = {
  color:'black',
  lineHeight: '1 !important',
  fontFamily: 'sutro !important',
  fontSize: '1.5rem !important'
}

const cartMemberBalance = {
  color:'black',
  float:'right',
  fontFamily: 'sutro !important',
  fontSize: '1rem !important',
  lineHeight: '1.5 !important',
}

const weightTableDesc = {
  marginTop: '32.5px !important',
  marginBottom: '8px !important'
}

const qrCodeWrap = {
  background: 'white',
  padding: 16
}


export {
  styleGuide,
  theme,
  loginStyle,
  paperStyle,
  dividerStyle,
  noTxtDeco,
  extLink,
  fullWidth,
  gridWrapPaper,
  gridItem,
  gridSpacer,
  fabProgress,
  loginButton,
  lockIcon,
  colorFormat,
  gridWrap,
  dashTable,
  sigCanvas,
  menuHeader,
  menuHeaderHig,
  menuHeaderTvHig,
  menuHeaderTv,
  menuHeaderCart,
  menuHeaderCartHig,
  menuHeaderCartChoiceSub,
  menuListHeader,
  menuListHeaderTv,
  menuListHeaderSmal,
  menuListHeaderSmalTv,
  menuListSecCart,
  menuListSec,
  menuListSecTv,
  menuDivider,
  menuDividerSmal,
  higBotDivider,
  higTopDivider,
  cartDivider,
  cartAdWrap,
  cartAdHeadline,
  cartAdSubline,
  cartAdVideo,
  menuWhiteBg,
  menuWhiteLogo,
  menuList,
  menuListHighlight,
  menuListHighlightCart,
  menuListHighlightCartMenu,
  menuListHighlightSpannabis,
  menuListHighlightSpannabisCart,
  menuListText,
  menuListTextTv,
  menuListMenuScreen,
  menuListMenuScreenCart,
  showCaseListHeader,
  showCaseListType,
  showCaseListTypeBig,
  showCaseItemName,
  showCaseItemParents,
  showCaseGreeting,
  showCaseName,
  showCaseBalance,
  cartMemberName,
  cartMemberBalance,
  weightTableDesc,
  qrCodeWrap,
  menuHeaderChoiceSub,
  menuListChoice
}
