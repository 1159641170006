import React from 'react'
import { Link } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import AppBar from '@mui/material/AppBar'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Input from '@mui/material/Input'
import Divider from '@mui/material/Divider'
import Modal from '@mui/material/Modal'
import InputLabel from '@mui/material/InputLabel'
import Toolbar from '@mui/material/Toolbar'
// import CameraIcon from '@mui/icons-material/Camera'
// import ScheduleIcon from '@mui/icons-material/Schedule'
import DashboardIcon from '@mui/icons-material/Dashboard'
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import LinearProgress from '@mui/material/LinearProgress'
// import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import {
  AccountBalance,
  Close,
  Print,
  OpenInNew,
  CheckCircle,
  Loop
} from '@mui/icons-material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import FormHelperText from '@mui/material/FormHelperText'
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant'
import { theme } from './Style'
import __ from '../util'

const TopBar = ({
  title,
  midTitle,
  action,
  onClick,
  iconLeft,
  onClickLeft,
  color,
  noUser,
  noAlert,
  noUpdate,
  noMemberCreate,
  noFinance,
  noDash,
  modeCancel,
  isActionAllowed = true,
  group
}) => (
  <AppBar
    position='static'
    color={color || 'default'}
    elevation={0}
    style={{ zIndex: 1200 }}
  >
    <Toolbar style={{ minHeight: '50px' }}>
      <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
        <div style={{ marginRight: 'auto' }}>
          {modeCancel && (
            <Typography
              variant='h5'
              color='inherit'
              onClick={onClickLeft}
              style={{
                fontSize: '16px',
                cursor: 'pointer'
              }}
            >
              Cancel
            </Typography>
          )}
          {iconLeft && (
            <IconButton
              aria-label='Menu'
              onClick={onClickLeft}
              color='inherit'
              style={{
                right: theme.spacing(2),
                marginRight: 'auto'
              }}
            >
              {iconLeft}
            </IconButton>
          )}
          {title && (
            <Typography
              variant='h5'
              color='inherit'
              style={{ lineHeight: '27px', fontSize: 28 }}
            >
              <span role="img" aria-label="herb">🌿</span>
              &nbsp;
              <span>HIG CSC</span>
            </Typography>
          )}
        </div>
      </div>
      <div>
        <Typography
          variant='h5'
          color='inherit'
          align='center'
          style={{
            flex: 1,
            fontSize: '16px',
            fontWeight: '700'
          }}
        >
          {midTitle || ''}
        </Typography>
      </div>
      <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
        <div style={{ marginLeft: 'auto' }}>
          {action && (
            <Typography
              variant='h5'
              color='inherit'
              onClick={onClick}
              style={{
                fontSize: '16px',
                cursor: isActionAllowed ? 'pointer' : 'not-allowed',
                opacity: isActionAllowed ? 1 : 0.5
              }}
            >
              {action}
            </Typography>
          )}
          {!noUpdate && (
            <Link to='/' style={{ color: 'white' }}>
              <IconButton
                aria-label='Menu'
                color='inherit'
                style={{
                  left: theme.spacing(2)
                }}
              >
                <Loop />
              </IconButton>
            </Link>
          )}
          {!noAlert && (
            <Link to='/coffeebreak' style={{ color: 'white' }}>
              <IconButton
                aria-label='Menu'
                color='inherit'
                style={{
                  left: theme.spacing(2)
                }}
              >
                <NotificationImportantIcon />
              </IconButton>
            </Link>
          )}
          {!noDash && (
            <Link to='/dash' style={{ color: 'white' }}>
              <IconButton
                aria-label='Menu'
                color='inherit'
                style={{
                  left: theme.spacing(2)
                }}
              >
                <DashboardIcon />
              </IconButton>
            </Link>
          )}
          {!noFinance && (
            <Link to='/finance' style={{ color: 'white' }}>
              <IconButton
                aria-label='Menu'
                color='inherit'
                style={{
                  left: theme.spacing(2)
                }}
              >
                <AccountBalance />
              </IconButton>
            </Link>
          )}
          {/*!noMemberCreate && (
            <Link to='/member/create' style={{ color: 'white' }}>
              <IconButton
                aria-label='Menu'
                color='inherit'
                style={{
                  left: theme.spacing(2)
                }}
              >
                <PersonAddAltIcon />
              </IconButton>
            </Link>
            )*/}
          {!noUser && (
            <Link to='/user' style={{ color: 'white' }}>
              <IconButton
                aria-label='Menu'
                color='inherit'
                style={{
                  left: theme.spacing(2)
                }}
              >
                <PersonOutlineIcon />
              </IconButton>
            </Link>
          )}
        </div>
      </div>
    </Toolbar>
  </AppBar>
)

const Snack = ({ msg, onClose }) => (
  <Snackbar
    open
    autoHideDuration={4000}
    transitionDuration={800}
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    onClose={onClose}
    ContentProps={{ 'aria-describedby': 'message-id' }}
    message={<span id='message-id'>{msg}</span>}
    action={[
      <IconButton
        key='close'
        aria-label='Close'
        color='inherit'
        onClick={onClose}
      >
        <Close />
      </IconButton>
    ]}
  />
)

const ExtLink = ({ to, txt, className, style }) => (
  <a
    href={to}
    target='_blank'
    rel='noopener noreferrer'
    className={className}
    style={style}
  >
    {txt}
  </a>
)

const TableColorFormaterPercent = ({ className, colorValue, text }) => {
  let cl = 'red'
  if (colorValue > 70) {
    cl = '#9CC17E'
  } else if (colorValue > 50) {
    cl = '#BDD7A9'
  } else if (colorValue > 20) {
    cl = '#A8C3F1'
  } else if (colorValue > 0.1) {
    cl = '#faedca'
  } else {
    cl = '#EBCFCC'
  }
  return (
    <span className={className} style={{ background: cl }}>
      {text}
    </span>
  )
}

const TableColorFormaterMargin = ({ className, colorValue, text }) => {
  return (
    <span
      className={className}
      style={{ background: __.colorValTable(colorValue) }}
    >
      {text}
    </span>
  )
}

const ExtBtn = () => <OpenInNew color='action' style={{ fontSize: 18 }} />

const CheckBtn = () => <CheckCircle color='action' style={{ fontSize: 18 }} />

class ModalCust extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
    this.children = props.children
    this.open = props.open == null ? true : props.open
    this.onClose = props.onClose
    this.onPrint = props.onPrint
    this.lbl = props.lbl || 'Error'
    this.fullScreen = props.fullScreen || false
    this.fullScreenNoCncl = props.fullScreenNoCncl || false
    this.showPrint = props.showPrint || false
    this.simple = props.simple || false
    const acs = props.actions || [{ lbl: 'OK', onClick: this.onClose }]
    if (!props.noCncl && this.lbl.toLowerCase() !== 'error') {
      acs.push({ lbl: 'Cancel', onClick: this.onClose })
    }
    this.btns = []
    for (const ac of acs) {
      this.btns.push(
        <Button
          key={ac.key || __.uuid()}
          onClick={
            props.withBusy
              ? async (...args) => {
                this.setState({ busy: true })
                const d = await ac.onClick(...args)
                return d
              }
              : ac.onClick
          }
        >
          {ac.lbl}
        </Button>
      )
    }
  }

  render () {
    if (this.fullScreen) {
      return (
        <Dialog
          fullScreen
          open={this.open}
          onClose={this.onClose}
          style={{ background: theme.palette.background.default }}
        >
          <AppBar style={{ position: 'relative' }}>
            <Toolbar>
              {!this.fullScreenNoCncl && <IconButton
                color='inherit'
                onClick={this.onClose}
                aria-label='Close'
              >
                <Close />
              </IconButton>}
              <Typography variant='h6' color='inherit' style={{ flex: 1 }}>
                {this.lbl}
              </Typography>
              {this.showPrint && <IconButton
                color='inherit'
                onClick={this.onPrint}
                aria-label='Print'
              >
                <Print />
              </IconButton>}
            </Toolbar>
          </AppBar>
          {this.children}
          {this.state.busy && <LinearProgress />}
        </Dialog>
      )
    } else if (this.simple) {
      return (
        <Dialog
          open={this.open}
          onClose={this.onClose}
          style={{ background: theme.palette.background.default }}
        >
          <DialogTitle>{this.lbl}</DialogTitle>
          <DialogContent>{this.children}</DialogContent>
          {!this.state.busy && this.btns && (
            <DialogActions>{this.btns}</DialogActions>
          )}
          {this.state.busy && <LinearProgress />}
        </Dialog>
      )
    } else {
      return (
        <Dialog
          open={this.open}
          onClose={this.onClose}
          style={{ background: theme.palette.background.default }}
        >
          <DialogTitle>{this.lbl}</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.children}</DialogContentText>
          </DialogContent>
          {!this.state.busy && this.btns && (
            <DialogActions>{this.btns}</DialogActions>
          )}
          {this.state.busy && <LinearProgress />}
        </Dialog>
      )
    }
  }
}

// TODO umbauen auf POPOVER https://material-ui.com/components/popover/
const ModalOver = ({ children, open = false, handleOnClose }) => (
  <Modal open={open} onClose={handleOnClose}>
    <div
      style={{
        position: 'absolute',
        width: '90%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2),
        top: `${50}%`,
        left: `${50}%`,
        transform: `translate(-${50}%, -${50}%)`,
        outline: 'none',
        overflow: 'auto',
        maxHeight: '90%'
      }}
    >
      {children}
    </div>
  </Modal>
)

class DropDown extends React.Component {
  // usage:
  //   <DropDown
  //     _id=<unique_id>
  //     data={this.state.<array_with_objs>}
  //     slctd={this.state.<label_of_selected_item}
  //     action={this.<action_function>}
  //     disabled={this.state.<disabled>}
  //     error={this.state.<inputError>}
  //     errorMsg={this.state.<inputErrorMsg>}
  //   />
  constructor (props) {
    super(props)
    this._id = props._id
    this.data = props.data
    this.title = props.title
    this.action = props.action
    this.renderValue = props.renderValue || (value => value)
    this.state = {
      slctd: props.slctd,
      disabled: props.disabled,
      error: props.error,
      errorMsg: props.errorMsg
    }
    this.handleChange = name => event => {
      this.setState({ [name]: event.target.value })
      this.action(
        this.data.find(i => {
          return i.lbl === event.target.value
        })
      )
    }
  }

  static getDerivedStateFromProps (props, state) {
    return {
      slctd: props.slctd,
      disabled: props.disabled,
      error: props.error,
      errorMsg: props.errorMsg
    }
  }

  render () {
    return (
      <FormControl fullWidth margin='normal'>
        <InputLabel htmlFor={this._id}>{this.title}</InputLabel>
        <Select
          value={this.state.slctd}
          onChange={this.handleChange('slctd')}
          input={<Input id={this._id} />}
          disabled={this.state.disabled}
          renderValue={this.renderValue}
          error={this.state.error}
        >
          {this.data.map(d => (
            <MenuItem key={d.key} value={d.lbl}>
              {d.lbl}
            </MenuItem>
          ))}
        </Select>
        {this.state.error && this.state.errorMsg && (
          <FormHelperText>{this.state.errorMsg}</FormHelperText>
        )}
      </FormControl>
    )
  }
}

const TosContract = ({ docNumber, lastName, firstName, address, email, birthday, monthlyG, signatureDataUri, dateToday, created, habitualChecked, medicalChecked }) => (
  <div>
    <Typography variant='h6' gutterBottom style={{fontFamily:'sutro'}}>
      Asociación cannábica <nobr>“Milena Verda”</nobr><br />Solicitud admisión socio libre consumidor
    </Typography>
    <Typography variant='subtitle1'>
      DNI/NIE/Idem: {docNumber}
    </Typography>
    <Typography variant='subtitle1'>
      Apellidos: {lastName}
    </Typography>
    <Typography variant='subtitle1'>
      Nombre: {firstName}
    </Typography>
    <Typography variant='subtitle1'>
      Domicilio: {address}
    </Typography>
    <Typography variant='subtitle1'>
      Email: {email}
    </Typography>
    <Typography variant='subtitle1' gutterBottom>
      Fecha de nacimiento: {birthday}
    </Typography>
    <Divider style={{marginBottom: 20, marginTop: 20}} />
    <Typography variant='body1'>
      La suscrita persona declara, por intermedio de la presente solicitud, bajo juramento o promesa de decir verdad SER CONSUMIDORA HABITUAL DE CANNABIS SATIVA L. Y OTRAS PLANTAS, Y DE TABACO, y AFIRMA su voluntad de incorporarse como Socia a la <b>ASOCIACIÓN CANNÁBICA MILENA VERDA</b>, conociendo los estatutos, fines y objetivos de ésta, se compromete a cumplir con ellos, con las normas de funcionamiento interno, respetar las decisiones de sus órganos de gobierno y cumplir con la legislación española. Teniendo en especial consideración los siguientes preceptos:
      <br /><br />
      El <b>artículo 36.16</b> de la Ley Orgánica 4/2015, de 30 de marzo, de Protección de la seguridad ciudadana prevé que “Constituyen infracciones graves a la seguridad ciudadana el consumo o la tenencia ilícitos de drogas tóxicas, estupefacientes o sustancias psicotrópicas, aunque no estuvieran destinadas al tráfico, en lugares, vías, establecimientos públicos o transportes colectivos, así como el abandono de los instrumentos u otros efectos empleados para ello en los citados lugares”.
      <br /><br />
      El <b>artículo 368</b> del Código Penal Español, Ley Orgánica 10/1995 prevé como delito “Los que  ejecuten actos de cultivo, elaboración o tráfico, o de otro modo promuevan, favorezcan o faciliten el  consumo ilegal de drogas tóxicas, estupefacientes o sustancias psicotrópicas, o las posean con aquellos  fines, serán castigados con las penas de prisión de tres a seis años y multa del tanto al triplo del valor  de la droga objeto del delito si se tratare de sustancias o productos que causen grave daño a la salud,  y de prisión de uno a tres años y multa del tanto al duplo en los demás casos.
      <br /><br />
      El <b>artículo 19</b> de la de la Ley Orgánica 4/2015, de 30 de marzo, de Protección de la seguridad  ciudadana prevé textualmente que: “1) Los agentes de las Fuerzas y Cuerpos de Seguridad podrán  limitar o restringir la circulación o permanencia en vías o lugares públicos y establecer zonas de  seguridad en supuestos de alteración de la seguridad ciudadana o de la pacífica convivencia, o cuando  existan indicios racionales de que pueda producirse dicha alteración, por el tiempo imprescindible  para su mantenimiento o restablecimiento. Asimismo, podrán ocupar preventivamente los efectos o instrumentos susceptibles de ser utilizados para acciones ilegales, dándoles el destino que legalmente proceda. 2) Para la prevención de delitos de especial gravedad o generadores de alarma social, así  como para el descubrimiento y detención de quienes hubieran participado en su comisión y proceder  a la recogida de los instrumentos, efectos o pruebas, se podrán establecer controles en las vías, lugares  o establecimientos públicos, siempre que resulte indispensable proceder a la identificación de las personas que se encuentran en ellos, al registro de vehículos o al control superficial de efectos  personales”.
      <br /><br />
      El <b>artículo 18</b> de la Constitución Española: 1. Se garantiza el derecho al honor, a la intimidad personal y familiar y a la propia imagen. 2. El domicilio es inviolable. Ninguna entrada o registro podrá hacerse en él sin consentimiento del titular o resolución judicial, salvo en caso de flagrante delito. 3.  Se garantiza el secreto de las comunicaciones y, en especial, de las postales, telegráficas y telefónicas, salvo resolución judicial.4. La Ley limitará el uso de la informática para garantizar el honor y la intimidad personal y familiar de los ciudadanos y el pleno ejercicio de sus derechos.
      <br /><br />
      1. El/la soci@ se compromete y responsabiliza a dedicar el cannabis retirado de la Asociación correspondiente ya sea de compra mancomunada o del cultivo colectivo ÚNICA Y EXCLUSIVAMENTE A SU CONSUMO PERSONAL EN EL ÁMBITO PRIVADO DE LA ASOCIACIÓN COMO AUTOCONSUMO, asumiendo cualquier responsabilidad de sus actos contrarios a la Ley que se pudieran derivar y eximiendo de ello a la Asociación, Socios de la Asociación, así como a la Junta Directiva. La Junta Directiva asume todas las responsabilidades derivadas de la gestión y administración de la Asociación dentro del local social.
      <br /><br />
      2. Para poder cumplir con los requisitos obligatorios para ser socio, a este efecto adjunta el patrocinio y/o aval de UN/A SOCIO/A que ya era previamente socio/a a la presente solicitud que manifiesta a su vez que la persona solicitante es consumidora habitual de cannabis aportando su número de socio, firma y fecha.
      <br /><br />
      3. El/la socio/a declara consumir {monthlyG || '____________'}gr. de Cannabis al mes, cantidad que deberá ser revisada o confirmada por el/la socio/a cada trimestre. En caso de no confirmar o revisar se prorrogará la cantidad ya declarada. La Asociación siempre entregará cantidades inferiores al consumo manifestado por el/la socio/a con el fin de concienciar hacia un consumo responsable y que se cumpla estrictamente con el plan individualizado de  consumo que cada socio/a ha escogido libremente.
      <br /><br />
      4. El/la socio/a autoriza a los/las socios activos, colaboradores o fundadores de la Asociación  a cultivar en el caso de que se tuviese un cultivo para el consumo colectivo, recoger o realizar  una compra mancomunada para repartir entre los/las socios/as el Cannabis siempre para el  autoconsumo dentro de la Asociación, quedando terminantemente prohibido su consumo o  tenencia en la vía pública, caso en el cual el socio/a quedará automáticamente expulsado de  la Asociación y perderá su condición de socio/a que ostentara hasta el momento.
      <br /><br />
      5. El/la socio/a se obliga a comunicar de inmediato, en el momento que decida abandonar su participación en la Asociación, la solicitud de baja de la Asociación. Anualmente deberá ratificarse la condición de socio/a.
      <br /><br />
      6. El/la socio/a comunicará a la Asociación la notificación de sanción en base al <b>artículo 36.16</b> de la Ley Orgánica 4/2015, de 30 de marzo, de Protección de la seguridad ciudadana, o la imputación del delito tipificado en el <b>artículo 368</b> del Código Penal. En ambos casos, y en consonancia con el apartado 4º enunciado, estas circunstancias implicarán la expulsión del socio/a de la asociación.
      <br /><br />
      7. El/la soci@ se compromete a no ceder su carnet soci@, el cual es intransferible; la transferencia del carnet de soci@ será motivo de expulsión de la asociación. La persona socia siempre exhibirá su documento de identidad y el carnet de socia para retirar su material.
      <br /><br />
      8. Al tener el/la soci@ el carácter de consumidor habitual de cannabis, en caso de no asistir a  la Asociación en el plazo continuado de 1 mes, esta circunstancia determinará la caducidad  o pérdida de su condición de socio, y en consecuencia también perderá la cuota de  inscripción que estuviese vinculada a su condición de socio hasta el momento.
      <br /><br />
      9. Cualquier incumplimiento de los compromisos adquiridos mediante la firma del presente  implicará la expulsión de la Asociación mediante el correspondiente procedimiento  sancionador y/o disciplinario fijado en los estatutos o en el Reglamento Interno de la  Asociación.
      <br /><br />
      10. Asimismo el/la socio/a admite y declara no padecer trastorno psicológico alguno y no estar  en tratamiento con psicofármacos.
      <br /><br />
      La solicitante manifiesta haber leído los estatutos y la presente solicitud de admisión de persona socia.
      <Divider style={{marginBottom: 20, marginTop: 20}} />
      DATOS DEL <b>SOCIO AVALADOR</b> DE LA PERSONA SOLICITANTE QUE DECLARA QUE EL/LA SOLICITANTE ES CONSUMIDOR/A HABITUAL DE CANNABIS:
      <br /><br />
      FECHA: {dateToday}
      <br /><br />
      NÚMERO DE SOCIO: {docNumber}
      <Divider style={{marginBottom: 20, marginTop: 20}} />
      DATOS DE LA PERSONA SOLICITANTE:
      <br /><br />
      DEBERÁ ESCOGER ENTRE LAS 2 SIGUIENTES OPCIONES: SOCIO LÚDICO O SOCIO TERAPÉUTICO
      <br /><br />
      A) SOCIO LÚDICO: {habitualChecked ? 'X': ''}
      <br /><br />
      B) SOCIO TERAPÉUTICO: {medicalChecked ? 'X': ''}
      <br /><br />
      FECHA DE LA SOLICITUD DE LA PERSONA INTERESADA EN SER SOCIO/A: {created || dateToday}
      <br /><br />
      FIRMA PERSONA INTERESADA EN SER SOCIO /A:<br />
      <img alt='signatureMember' src={signatureDataUri} style={{maxWidth:'180px'}} />
      <br /><br />
      FIRMA DEL SOCIO AVALADOR QUE DECLARA QUE LA PERSONA SOLICITANTE ES CONSUMIDOR HABITUAL DE CANNABIS:<br />
      <img alt='signatureClub' src='https://cdn.higcsc.com/sig/signature-april-2023.jpg' style={{maxWidth:'170px'}} />
      <br /><br />
      <b>REUNIÓN DE LA JUNTA DIRECTIVA</b>
      <br /><br />
      SE ACEPTA LA PRESENTE SOLICITUD: SI
      <br /><br />
      FECHA DE LA REUNIÓN DE LA JUNTA DIRECTIVA: {created || dateToday}
    </Typography>
    <Divider style={{marginBottom: 20, marginTop: 20}} />
    <Typography variant='caption'>
      En cumplimiento de lo dispuesto por el artículo 13 del Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo  a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos, y por el que se deroga  la Directiva 95/46/CE, se le informa de los siguientes aspectos relacionado con el tratamiento de los datos de carácter personal que nos proporciona mediante este documento y, en su caso, de los que genere la relación asociativa.
      <br /><br />
      a) El Responsable del tratamiento de sus datos personales es la ASOCIACIÓN CANNÁBICA MILENA VERDA con domicilio en C/ Bailen, 110, bxs., 1ª, 08009 (Barcelona).
      <br />
      b) La ASOCIACIÓN CANNÁBICA MILENA VERDA ha designado como Delegado de Protección de Datos (DPO) al Presidente de la Asociación de tal forma que Vd.  podrá contactar con él en con domicilio en C/ Bailen, 110, bxs., 1ª, 08009 (Barcelona),
      <br />
      c) Le informamos de que los datos personales que nos proporciona son necesarios para el adecuado mantenimiento, desarrollo, cumplimiento y control de la prestación de los servicios que el socio demanda, motivo por el cual serán tratados con dicha finalidad de gestionar la relación asociativa existente entre Vd. y la Asociación.
      <br /><br />
      La base jurídica de ese tratamiento de datos es el cumplimiento de las obligaciones legales correspondientes.
      <br /><br />
      Asimismo, le informamos de que, de marcar la correspondiente casilla, utilizaremos sus datos personales para enviarle correos electrónicos y/o SMS  a su teléfono móvil, con información, newsletter, y servicios que pudieran resultar de su interés.
      <br /><br />
      No obstante, Vd. puede revocar su consentimiento en cualquier momento notificando por escrito en con domicilio en C/ Bailen, 110, bxs., 1ª, 08009 (Barcelona).
      <br /><br />
      Los datos personales que nos proporciona mediante este documento y durante la relación entre socio y Asociación, se conservarán durante el plazo de vigencia de la misma. Sus datos serán guardados siempre de forma privada, segura y confidencial y no serán facilitados a ninguna persona o Autoridad a no ser que una resolución judicial lo acuerde.
      <br /><br />
      Del mismo modo, le informamos de que en cualquier momento puede solicitarnos el acceso a sus datos personales o, en su caso, su rectificación o supresión, la limitación del tratamiento en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones, su oposición al mismo, así como su derecho a la portabilidad de los datos personales. Para ello deberá enviar una solicitud por escrito dirigida a la ASOCIACIÓN CANNÁBICA MILENA VERDA con domicilio en C/ Bailen, 110, bxs., 1ª, 08009 (Barcelona)
      <br /><br />
      Por último, le informamos de que tiene derecho a presentar una reclamación ante la autoridad de control (bien sea la Agencia Española de Protección de Datos, o la Autoridad Catalana de Protección de Datos), bien a través de su sede electrónica o en su domicilio, en Calle Jorge Juan, número 6, 28001,  Madrid.
      <br /><br />
      La finalidad y uso previsto tanto de los datos en sí mismos como de su tratamiento, es prestarle el servicio solicitado.
      <br /><br />
      A continuación, podrá aceptar las finalidades que crea convenientes marcando su casilla correspondiente, tenga en cuenta que algunas finalidades pueden ser necesarias para poderle prestar el servicio, en el caso de NO marcar dichas casillas, no se podrá prestar el servicio asociado.
      <br /><br />
      [ X ] Prestación del servicio solicitado (Si acepta el tratamiento de sus datos con esta finalidad marque esta casilla).
      <br />
      [ X ] Envío de correos electrónicos y/o SMS a su teléfono móvil, con información, newsletter, y servicios de su interés (Si acepta el tratamiento de sus  datos con esta finalidad marque esta casilla).
      <br /><br />
      Finalmente, de acuerdo con el artículo 3, apartado b) de la Instrucción 1/2006, de 8 de noviembre, de la Agencia Española de Protección de Datos, sobre  el tratamiento de datos personales con fines de vigilancia a través de sistemas de cámaras o videocámaras, así como con el artículo 11 de la Ley Orgánica  3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales, y el artículo 13 del Reglamento (UE) 2016/679 del  Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos  personales y a la libre circulación de estos datos, el socio queda informado de que la ASOCIACIÓN CANNÁBICA MILENA VERDA ha instalado un sistema de video  vigilancia mediante cámaras en el interior del local situado C/ Bailen, 110, bxs., 1ª, 08009 (Barcelona), para garantizar la seguridad de todos  los usuarios y todas aquellas personas que concurran al interior de las instalaciones de la Asociación.
      <br /><br />
      Así mismo, el socio tiene a su disposición una cláusula informativa ampliada relativa a la grabación y captación de imágenes, pudiendo ejercer en  todo momento su derecho de acceso, rectificación, cancelación, oposición, portabilidad y limitación del tratamiento de las imágenes ante la ASOCIACIÓN CANNÁBICA MILENA VERDA.
    </Typography>
  </div>
)

export {
  TopBar,
  Snack,
  ModalCust,
  ModalOver,
  DropDown,
  ExtLink,
  ExtBtn,
  CheckBtn,
  TableColorFormaterPercent,
  TableColorFormaterMargin,
  TosContract
}
