import React from 'react'
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  LinearProgress
} from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import * as mo from 'moment'
import { withStyles } from '@mui/styles'
import { TopBar, Snack } from './Lib'
import {
  styleGuide,
  paperStyle,
  weightTableDesc
} from './Style'
import __ from '../util'

class Finance extends React.Component {
  constructor (props) {
    super(props)
    this.cx = props.cx
    this.widthType = props.width
    this.reset = {
      weightItems: [],
      depositAmount: '',
      takeoutAmount: '',
      depositComment: '',
      depositDate: mo().subtract(1, 'day'),
      donationSumToday: 0,
      donationSumYesterday: 0,
      loading: false
    }
    this.state = { ...this.reset }
    this.getWeightItems = this.getWeightItems.bind(this)
    this.getDeposits = this.getDeposits.bind(this)
    this.handleCashDeposit = this.handleCashDeposit.bind(this)
    this.handleGoBack = () => {
        this.props.history.push('/dash')
    }
  }

  async handleCashDeposit () {
    const weightItems = this.state.weightItems
    const donationSum = this.state.donationSumYesterday

    this.setState({ loading: true })
    try {
      await this.cx.dash.cashDeposit(__.getJsonSto('core').loginemail, this.state.depositDate.format(), this.state.depositComment, this.state.depositAmount, this.state.takeoutAmount)
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }
    this.setState({
      ...this.reset,
      weightItems,
      donationSum,
      snack: 'Deposit succesfull!',
      loading: false
    }) 
  }

  async handleWeight () {
    this.setState({ loading: true })
    const myState = this.state
    const weights = Object.keys(myState)
    .filter((key) => key.includes('WEIGHT'))
    .reduce((obj, key) => {
      if(myState[key] !== '') {
        return Object.assign(obj, {
          [key]: myState[key]
        });
      } else {
        return obj
      }
    }, {});
    const refills = Object.keys(myState)
    .filter((key) => key.includes('REFILL'))
    .reduce((obj, key) => {
      if(myState[key] !== '') {
        return Object.assign(obj, {
          [key]: myState[key]
        });
      } else {
        return obj
      }
    }, {});
    const comments = Object.keys(myState)
    .filter((key) => key.includes('COMMENT'))
    .reduce((obj, key) => {
      if(myState[key] !== '') {
        return Object.assign(obj, {
          [key]: myState[key]
        });
      } else {
        return obj
      }
    }, {});

    try {
      await this.cx.dash.weightSubmit(__.getJsonSto('core').loginemail, weights, refills, comments)
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }
    this.setState(this.reset)

    await this.getWeightItems()

    this.setState({
      snack: 'Weight & Refill submitted!'
    })
  }

  async getWeightItems () {
    this.setState({ loading: true })

    let items

    try {
      [items] = await Promise.all([
        this.cx.dash.getWeightItems()
      ])
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }

    if(Array.isArray(items) && items.length > 0) {
      const newState = {
        weightItems: items,
        loading: false
      }
      for (const item of items) {
        newState['WEIGHT' + item.idItem] = ''
        newState['COMMENT' + item.idItem] = ''
        newState['REFILL' + item.idItem] = ''
      }

      this.setState(newState)
    }
  }

  async getDeposits () {
    let deposits

    try {
      [deposits] = await Promise.all([
        this.cx.dash.getDeposits()
      ])
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }

    this.setState({
      donationSumToday: deposits.donationSumToday,
      donationSumYesterday: deposits.donationSumYesterday
    })
  }

  async componentDidMount () {
    // set body bg
    document.body.style.backgroundColor = styleGuide.backgroundDark

    Object.assign(this, __.initView(this, 'finance'))

    await Promise.all([
     this.getWeightItems(),
     this.getDeposits()
   ])
  }

  render () {
    if (this.state.loading) {
      return <LinearProgress />
    } else {
      return (
        <div>
          {this.state.snack && (
            <Snack
              msg={this.state.snack}
              onClose={() => this.setState({ snack: undefined })}
            />
          )}
          <TopBar
            noUser
            noAlert
            noUpdate
            noCart
            noSchedule
            noBackoffice
            noMemberCreate
            noDash
            noFinance
            iconLeft={<ArrowBack />}
            onClickLeft={this.handleGoBack}
            midTitle='Finance'
          />
          <Paper className={this.props.classes.paperStyle}>
            <Typography variant='h2' gutterBottom>
              Weight
            </Typography>
            {this.state.weightItems.map((item, index) => <Grid container spacing={1} key={item.idItem}>
                {(index === 0 || item.category !== this.state.weightItems[index - 1].category) ? <Grid item xs={12}><Typography variant='h4' style={{paddingTop: 36}}>{item.category}</Typography></Grid> : ''}
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id={'weight'+ item.idItem}
                    label={item.name + ' weight start'}
                    margin='normal'
                    inputProps={{ inputMode: 'decimal' }}
                    value={this.state['WEIGHT' + item.idItem]}
                    onChange={(event) => {
                      const numberOnly = event.target.value.replace(/[^0-9.]/g,'')
                      this.setState({
                        ['WEIGHT' + item.idItem]: numberOnly
                      })
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Typography variant='body1' className={this.props.classes.weightTableDesc}>
                    {item.refill ? __.formatNumber(item.refill + item.weight) : __.formatNumber(item.weight)}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id={'refill'+ item.idItem}
                    label={item.name + ' refill final'}
                    margin='normal'
                    inputProps={{ inputMode: 'decimal' }}
                    value={this.state['REFILL' + item.idItem]}
                    onChange={(event) => {
                      const numberOnly = event.target.value.replace(/[^0-9.]/g,'')
                      this.setState({
                        ['REFILL' + item.idItem]: numberOnly,
                        ['WEIGHT' + item.idItem]: this.state['WEIGHT' + item.idItem] ? this.state['WEIGHT' + item.idItem] : 0 
                      })
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    id={'comment'+ item.idItem}
                    label={item.name + ' Comment'}
                    margin='normal'
                    value={this.state['COMMENT' + item.idItem]}
                    onChange={(event) => {
                      this.setState({
                        ['COMMENT' + item.idItem]: event.target.value
                      })
                    }}
                  />
                </Grid>
            </Grid>)}
            <Button
              fullWidth
              variant='contained'
              color='primary'
              style={{marginTop: 20}}
              onClick={async event => this.handleWeight(event)}
            >Submit updated weights</Button>

            <Typography variant='h2' gutterBottom style={{marginTop: 60}}>
              Deposit
            </Typography>
            <Typography variant='caption' gutterBottom>
              Today {mo().format(__.cfg('tsYearFormat'))}
            </Typography>
            <Typography variant='body1' gutterBottom  style={{marginBottom: 20}}>
              Member donations {__.formatNumber(this.state.donationSumToday)} €
            </Typography>
            <Typography variant='caption' gutterBottom>
              Yesterday {this.state.depositDate.format(__.cfg('tsYearFormat'))}
            </Typography>
            <Typography variant='body1' gutterBottom>
              Member donations {__.formatNumber(this.state.donationSumYesterday)} €
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <Typography variant='body1' className={this.props.classes.weightTableDesc}>
                  {this.state.depositDate.format(__.cfg('tsYearFormat'))}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label='Comment'
                  margin='normal'
                  value={this.state.depositComment}
                  onChange={(event) => {
                    this.setState({
                      depositComment: event.target.value
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  label='Initial amount in €'
                  margin='normal'
                  inputProps={{ inputMode: 'numeric' }}
                  value={this.state.depositAmount}
                  onChange={(event) => {
                    const numberOnly = event.target.value.replace(/\D/g,'')

                    if((Number.isInteger(parseInt(numberOnly)) && parseInt(numberOnly) !== 0 && parseInt(numberOnly) > 0) || event.target.value === '') {
                      this.setState({
                        depositAmount: numberOnly
                      })
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  label='Takeout total in €'
                  margin='normal'
                  inputProps={{ inputMode: 'decimal' }}
                  value={this.state.takeoutAmount}
                  onChange={(event) => {
                    const amount = event.target.value ? event.target.value.replace(',', '.').replace(/[^0-9,.]+/g, '') : ''
                    this.setState({
                      takeoutAmount: amount
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant='body1' className={this.props.classes.weightTableDesc}>
                  Final amount: {this.state.depositAmount - this.state.takeoutAmount} €
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  disabled={this.state.depositAmount === ''}
                  onClick={async event => this.handleCashDeposit(event)}
                >Submit cash deposit</Button>
              </Grid>
            </Grid>
          </Paper>
        </div>
      )
    }
  }
}

export default
  withStyles({
    paperStyle,
    weightTableDesc
  })(Finance)
