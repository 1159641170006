// import * as mo from 'moment'
import { StoBase } from './Lib'
import * as mo from 'moment'
import __ from '../util'

export default class Dash extends StoBase {
  constructor (cx, token) {
    super('Dash', cx, '763326a9-d684-4de8-91df-38e50726d7de')
    this.token = token
    this.getFilterSto = page => __.getJsonSto(`filter_${page}`)
    this.setFilterSto = (page, filterList) =>
      __.setJsonSto(`filter_${page}`, filterList)
    this.getBxpSto = () => __.getSto('bxp')
    this.setBxpSto = val => __.setSto('bxp', val)
    this.getMembers = this.getMembers.bind(this)
    this.getItems = this.getItems.bind(this)
    this.getWeightItems = this.getWeightItems.bind(this)
    this.getDeposits = this.getDeposits.bind(this)
    this.getDonations = this.getDonations.bind(this)
    this.createAlert = this.createAlert.bind(this)
    this.getAlert = this.getAlert.bind(this)
    this.cashDeposit = this.cashDeposit.bind(this)
    this.weightSubmit = this.weightSubmit.bind(this)
  }

  async getMembers () {
    const pld = await this.rqst(
      {
        url: 'member',
        params: {}
      },
      this.token
    )
    return pld.map(row => {
      return {
        ...row,
        active: row.active === 1 ? true : false,
        birthday: mo(row.birthday),
        created: mo(row.created),
        membershipValidUntil: mo(row.membershipValidUntil),
        lastCheckin: row.lastCheckin ? mo(row.lastCheckin) : undefined
      }
    })
  }

  async getItems () {
    const pld = await this.rqst(
      {
        url: 'item',
        params: {}
      },
      this.token
    )
    return pld
  }

  async getWeightItems () {
    const weightCats = ['Indica', 'Sativa', 'Hybrid', 'Hashish', 'CBD Flower', 'Joint TopShelf', 'Joint Handpicked', 'Joint Regular','Specials', 'Bundles', 'Drinks', 'Premium Hash']

    const pld = await this.rqst(
      {
        url: 'item',
        params: {}
      },
      this.token
    )
    return pld.filter(i => weightCats.includes(i.category)).sort((a,b) => {
      return a.category.localeCompare(b.category)
    })
  }

  async getDeposits () {
    const pld = await this.rqst(
      {
        url: 'finance/deposit',
        params: {}
      },
      this.token
    )
    return pld
  }

  async getDonations () {
    const pld = await this.rqst(
      {
        url: 'donations',
        params: {}
      },
      this.token
    )
    return pld
  }

  async createAlert (createdBy) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'alert',
        method: 'POST',
        data: { createdBy }
      },
      token
    )
    return pld
  }

  async getAlert () {

    const pld = await this.rqst(
      {
        url: 'alert'
      },
      this.token
    )
    return pld
  }

  async cashDeposit (createdBy, date, comment, amount, takeout) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'finance/deposit',
        method: 'POST',
        data: { createdBy, date, comment, amount, takeout }
      },
      token
    )
    return pld
  }

  async weightSubmit (createdBy, weights, refills, comments) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'finance/weight',
        method: 'POST',
        data: { createdBy, weights, refills, comments }
      },
      token
    )
    return pld
  }
}
