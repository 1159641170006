import React from 'react'
import {
  Paper,
  LinearProgress,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material'
import * as mo from 'moment'
import { withStyles } from '@mui/styles'
import { Snack, ModalCust } from './Lib'
import {
  // styleGuide,
  menuHeaderTv,
  menuListHeaderTv,
  menuListHeaderSmalTv,
  menuListSecTv,
  menuDivider,
  higBotDivider,
  higTopDivider,
  menuWhiteBg,
  menuWhiteLogo,
  menuList,
  menuListHighlight,
  menuListHighlightSpannabis,
  menuListMenuScreen,
  menuHeaderChoiceSub,
  menuListChoice
} from './Style'
import __ from '../util'

class MenuScreen1 extends React.Component {
  constructor (props) {
    super(props)
    this.cx = props.cx
    this.widthType = props.width
    this.reset = {
      bgColor: mo().hour() > 19 ? '#37493E' : '#5E7D6B',
      // bgColor: '#5E7D6B',
      menu: undefined
    }
    this.state = {
      ...this.reset
    }
    this.getMenu = this.getMenu.bind(this)
    this.updateRunning = false
  }

  async getMenu () {
    let menu

    if(!this.updateRunning) {
      this.updateRunning = true
      try {
        [menu] = await Promise.all([
          this.cx.core.getMenuScreen(1)
        ])
      } catch (e) {
        if (__.cfg('isDev')) throw e
        /*const emsg = e.message
        __.clearSto()
        this.setState({ err: emsg })
        throw e
        */
        console.log(e)
      }
      if(Array.isArray(menu)) {
        this.setState({
          bgColor: mo().hour() > 19 ? '#37493E' : '#5E7D6B',
          // bgColor: '#5E7D6B',
          menu
        })
      }
      this.updateRunning = false
    } else {
      // console.log('skip');
    }
    if(!this.interval) {
        this.interval = setInterval(() => this.getMenu(), __.cfg('refreshMenuView'))
    }
  }

  async componentDidMount () {

    Object.assign(this, __.initView(this, 'menu'))

    // set body bg
    document.body.style.backgroundColor = this.state.bgColor

    await this.getMenu()
  }

  componentWillUnmount() {
    // console.log('hi unmount', this.interval)
    if(this.interval) {
      clearInterval(this.interval)
    }
  }

  render () {
    if (this.state.err) {
      return (
        <ModalCust
          actions={[
            {
              lbl: 'Go to login',
              onClick: () => this.props.history.push('/login')
            }
          ]}
        >
          {this.state.err}
        </ModalCust>
      )
    } else if (this.state.menu && this.state.menu.length > 0) {
      return (
        <Paper square elevation={0} style={{backgroundColor: this.state.bgColor, height: '100vh'}}>
          {this.state.snack && (
            <Snack
              msg={this.state.snack}
              onClose={() => this.setState({ snack: null })}
            />
          )}
          <div style={{ margin: 0, position: 'absolute', top: '50%', transform: 'translateY(-50%)', width: '100%'}}>
            <Grid container justifyContent='center' spacing={2} style={{padding:20}}>
              <Grid item xs={12}>
                <img
                  src='//cdn.higcsc.com/menu/hig-div-trans-top.png'
                  alt='Btm Div'
                  className={this.props.classes.higTopDivider}
                  style={{marginBottom: 60}}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant='h2' align='center' className={this.props.classes.menuHeaderTv}>Indica</Typography>
                {this.state.menu.filter((el) => el.idCategory === 1 && el.activeMenu === 1 && el.activeChoice !== 1).map((item) => <List className={this.props.classes.menuList} key={item.idItem}>
                    <ListItem className={(item.spannabis === 1 ? this.props.classes.menuListHighlightSpannabis + ' ' : '') + (item.highlight === 1 ? this.props.classes.menuListHighlight : this.props.classes.menuList)}>
                      <ListItemText
                        disableTypography
                        className={this.props.classes.menuListMenuScreen}
                        primary={
                          <React.Fragment>
                            <Typography
                              variant='body2'
                              className={this.props.classes.menuListHeaderTv}
                              color='textPrimary'
                            >
                              {item.name}
                            </Typography>
                          </React.Fragment>
                        }
                        secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecTv}>{item.parents}</Typography>}
                      />
                    </ListItem>
                  <Divider className={this.props.classes.menuDivider} />
                </List>)}
              </Grid>
              <Grid item xs={4}>
                <Typography variant='h2' align='center' className={this.props.classes.menuHeaderTv}>Hybrid</Typography>
                {this.state.menu.filter((el) => el.idCategory === 2 && el.activeMenu === 1 && el.activeChoice !== 1).map((item) => <List className={this.props.classes.menuList} key={item.idItem}>
                    <ListItem className={(item.spannabis === 1 ? this.props.classes.menuListHighlightSpannabis + ' ' : '') + (item.highlight === 1 ? this.props.classes.menuListHighlight : this.props.classes.menuList)}>
                      <ListItemText
                        disableTypography
                        className={this.props.classes.menuListMenuScreen}
                        primary={
                          <React.Fragment>
                            <Typography
                              variant='body2'
                              className={this.props.classes.menuListHeaderTv}
                              color='textPrimary'
                            >
                              {item.name}
                            </Typography>
                          </React.Fragment>
                        }
                        secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecTv}>{item.parents}</Typography>}
                      />
                    </ListItem>
                  <Divider className={this.props.classes.menuDivider} />
                </List>)}
              </Grid>
              <Grid item xs={4}>
                <Typography variant='h2' align='center' className={this.props.classes.menuHeaderTv}>Sativa</Typography>
                {this.state.menu.filter((el) => el.idCategory === 3 && el.activeMenu === 1 && el.activeChoice !== 1).map((item) => <List className={this.props.classes.menuList} key={item.idItem}>
                    <ListItem className={(item.spannabis === 1 ? this.props.classes.menuListHighlightSpannabis + ' ' : '') + (item.highlight === 1 ? this.props.classes.menuListHighlight : this.props.classes.menuList)}>
                      <ListItemText
                        disableTypography
                        className={this.props.classes.menuListMenuScreen}
                        primary={
                          <React.Fragment>
                            <Typography
                              variant='body2'
                              className={this.props.classes.menuListHeaderTv}
                              color='textPrimary'
                            >
                              {item.name}
                            </Typography>
                          </React.Fragment>
                        }
                        secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecTv}>{item.parents}</Typography>}
                      />
                    </ListItem>
                  <Divider className={this.props.classes.menuDivider} />
                </List>)}
              </Grid>

              <Grid item xs={12}>
                <img
                  src='//cdn.higcsc.com/menu/hig-div-trans-bot.png'
                  alt='Btm Div'
                  className={this.props.classes.higBotDivider}
                  style={{marginTop: 120}}
                />
              </Grid>
            </Grid>
          </div>
        </Paper>
      )
    } else if(this.state.loading) {
      return <LinearProgress />
    } else {
      return <Paper square elevation={0} className={this.props.classes.menuWhiteBg}>
        <img
          src='//cdn.higcsc.com/higlogo-trans.png'
          alt='Logo HIG CSC'
          className={this.props.classes.menuWhiteLogo}
        />
      </Paper>
    }
  }
}

export default
  withStyles({
    menuHeaderTv,
    menuListHeaderTv,
    menuListHeaderSmalTv,
    menuListSecTv,
    menuDivider,
    higBotDivider,
    higTopDivider,
    menuWhiteBg,
    menuWhiteLogo,
    menuList,
    menuListHighlight,
    menuListHighlightSpannabis,
    menuListMenuScreen,
    menuHeaderChoiceSub,
    menuListChoice
  })(MenuScreen1)
