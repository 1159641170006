// import * as mo from 'moment'
import { StoBase } from './Lib'
import __ from '../util'

export default class Member extends StoBase {
  constructor (cx, token) {
    super('Member', cx, '443e8478-e656-4262-bea0-c8008e120804')
    this.token = token
    this.checkin = this.checkin.bind(this)
    this.donate = this.donate.bind(this)
    this.delete = this.delete.bind(this)
    this.setActive = this.setActive.bind(this)
    this.getImage = this.getImage.bind(this)
    this.getHistory = this.getHistory.bind(this)
    this.resendQr = this.resendQr.bind(this)
  }

  async checkin (createdBy, idMember) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'member/checkin',
        method: 'POST',
        data: { createdBy, idMember }
      },
      token
    )
    return pld
  }

  async donate (idMember, donateType, donateAmount) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'member/donate',
        method: 'POST',
        data: { idMember, donateType, donateAmount, createdBy: __.getJsonSto('core').loginemail }
      },
      token
    )
    return pld
  }

  async setActive (idMember, status) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'member/status',
        method: 'POST',
        data: { idMember, status }
      },
      token
    )
    return pld
  }

  async delete (idMember) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'member',
        method: 'DELETE',
        data: { idMember }
      },
      token
    )
    return pld
  }

  async getImage (type, imageId) {
    const pld = await this.rqst(
      {
        url: `member/image/${type}/${imageId}`
      },
      this.token
    )
    return pld
  }

  async getHistory (id) {
    const pld = await this.rqst(
      {
        url: `member/history/${id}`
      },
      this.token
    )
    return pld
  }

  async resendQr (qrSelected) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'member/email/qr',
        method: 'POST',
        data: { qrSelected }
      },
      token
    )
    return pld
  }

}
