import { StoBase } from './Lib'
import User from './User'
import Dash from './Dash'
import Member from './Member'
import Cart from './Cart'
import __ from '../util'

export default class Core extends StoBase {
  constructor (cx) {
    super('core', cx, '848f62f2-c2b0-4435-ac39-1c53e07a5430')
    this._store = 'core'
    this.isActive = () => Boolean(this.getSto())
    this.clear = this.clear.bind(this)
    this.init = this.init.bind(this)
    this.get = this.get.bind(this)
    this.getMenu = this.getMenu.bind(this)
    this.getMenuScreen = this.getMenuScreen.bind(this)
    this.uploadDocument = this.uploadDocument.bind(this)
    this.createMember = this.createMember.bind(this)
  }

  clear () {
    const cx = this.cx
    __.clearObj(cx)
    cx.core = new Core(cx)
    __.clearSto()
  }

  get (key) {
    const core = this.getSto() || {}
    const val = core[key]
    if (val === undefined) {
      this.clear()
      throw this.err(`Getting core data "${key}" failed`, { sts: 900 })
    }
    return val
  }

  init (email, token, loginemail, group) {
    if (token) {
      this.setSto({
        token,
        loginemail: loginemail,
        group: group
      })
    }

    const core = this.getSto()

    if (!core) return false

    if (!this.cx.user) {
      Object.assign(this.cx, {
        tmp: {},
        user: new User(this.cx, email)
      })
      Object.assign(this.cx, {
        dash: new Dash(this.cx, token || core.token),
        member: new Member(this.cx, token || core.token),
        cart: new Cart(this.cx, token || core.token)
      })
    }
    return true
  }

  async login (email, password) {
    this.clear()
    let pld
    try {
      pld = await this.rqst({
        url: 'login',
        method: 'post',
        data: { email, password }
      })
      this.init(email, pld.token, pld.loginemail, pld.group)
    } catch (e) {
      let emsg, sts
      if (e.sts === 404) {
        emsg = 'Invalid login/password combination'
        sts = 404
      } else if (e.sts >= 400 && e.sts < 500) {
        emsg = 'Invalid login/password combination'
        sts = 400
      } else {
        emsg = 'Login failed temporary: Please try again later'
        sts = e.sts
      }
      throw this.err(emsg, { e, sts })
    }
  }

  async getMenu (page) {
    let pld
    try {
      pld = await this.rqst({
        url: `menu${page && page.toString().length > 0 ? `/${page}` : ''}`
      })
    } catch (e) {
      let emsg, sts
      throw this.err(emsg, { e, sts })
    }
    return pld
  }

  async getMenuScreen (page) {
    let pld
    try {
      pld = await this.rqst({
        url: `menu/${page}`
      })
    } catch (e) {
      let emsg, sts
      throw this.err(emsg, { e, sts })
    }
    return pld
  }

  async uploadDocument (type, imagedata) {
    let pld
    try {
      pld = await this.rqst({
        url: 'member/document',
        method: 'post',
        data: { type, imagedata }
      })
    } catch (e) {
      let emsg, sts
      /*if (e.sts === 404) {
        emsg = 'Invalid login/password combination'
        sts = 404
      } else if (e.sts >= 400 && e.sts < 500) {
        emsg = 'Invalid login/password combination'
        sts = 400
      } else {
        emsg = 'Login failed temporary: Please try again later'
        sts = e.sts
      }*/
      throw this.err(emsg, { e, sts })
    }
    return pld
  }

  async createMember (email, firstName, lastName, address, ref, birthday, docType, docNumber, monthlyG, residentChecked, habitualChecked, medicalChecked, docImageId, faceImageId, signatureImageId) {
    const createdBy = __.getJsonSto('core') ? __.getJsonSto('core').loginemail : 'publicUser'

    let pld
    try {
      pld = await this.rqst({
        url: 'member',
        method: 'post',
        data: { email, firstName, lastName, address, ref, birthday, docType, docNumber, monthlyG, residentChecked, habitualChecked, medicalChecked, docImageId, faceImageId, signatureImageId, createdBy }
      })
    } catch (e) {
      let emsg, sts
      /*if (e.sts === 404) {
        emsg = 'Invalid login/password combination'
        sts = 404
      } else if (e.sts >= 400 && e.sts < 500) {
        emsg = 'Invalid login/password combination'
        sts = 400
      } else {
        emsg = 'Login failed temporary: Please try again later'
        sts = e.sts
      }*/
      throw this.err(emsg, { e, sts })
    }
    return pld
  }

}
