import React from 'react'
import Paper from '@mui/material/Paper'
import { ArrowBack } from '@mui/icons-material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { withStyles } from '@mui/styles'
import { TopBar, Snack, ModalCust } from './Lib'
import {
  styleGuide,
  paperStyle
} from './Style'
import __ from '../util'

class UserView extends React.Component {
  constructor (props) {
    super(props)
    this.cx = props.cx
    this.widthType = props.width
    this.state = {
      loginemail: __.getJsonSto('core').loginemail,
      group: __.getJsonSto('core').group
    }
    this.handleGoBack = () => {
        this.props.history.push('/dash')
    }

  }

  async componentDidMount () {
    // set body bg
    document.body.style.backgroundColor = styleGuide.backgroundDark

    Object.assign(this, __.initView(this, 'user'))
  }

  render () {
    if (this.state.err) {
      return (
        <ModalCust
          actions={[
            {
              lbl: 'Go to login',
              onClick: () => this.props.history.push('/login')
            }
          ]}
        >
          {this.state.err}
        </ModalCust>
      )
    } else {
      return (
        <div>
          {this.state.snack && (
            <Snack
              msg={this.state.snack}
              onClose={() => this.setState({ snack: null })}
            />
          )}
          <TopBar
            noUser
            noUpdate
            noAlert
            noMemberCreate
            noCart
            noSchedule
            noBackoffice
            noDash
            noFinance
            iconLeft={<ArrowBack />}
            onClickLeft={this.handleGoBack}
            midTitle='User'
          />
          <Paper square>
            <div className={this.props.classes.paperStyle}>
            <Typography variant='h4'>Info</Typography>
              <Typography variant='body1'>Email {this.state.loginemail}</Typography>
              <Typography variant='body1'>Group {this.state.group}</Typography>
              <Typography variant='body1'>Version {__.cfg('version')}</Typography>
            </div>
            <div className={this.props.classes.paperStyle}>
              <Typography variant='h4'>Lawyer (Jurista) </Typography>
              <Typography variant='body1'>Phone: +34 934 676 778</Typography>
              <Typography variant='body1'>Mobile: +34 647 903 081</Typography>
            </div>
            <div className={this.props.classes.paperStyle}>
              <Button
                variant='outlined'
                color='primary'
                onClick={() => {
                  this.cx.core.clear()
                  this.props.history.push('/login')
                }}
                style={{ marginTop: '60px' }}
              >
                Logout
              </Button>
            </div>
          </Paper>
        </div>
      )
    }
  }
}

export default
  withStyles({
    paperStyle
  })(UserView)
