import React from 'react'
import {
  Box,
  Paper,
  TextField,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormGroup,
  Select,
  Checkbox,
  Button
} from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import * as mo from 'moment'
import { withStyles } from '@mui/styles'
import Camera from 'react-html5-camera-photo'
import QRCode from 'react-qr-code'
import 'react-html5-camera-photo/build/css/index.css'
import SignatureCanvas from 'react-signature-canvas'
import { TopBar, Snack, ModalCust, TosContract } from './Lib'
import {
  styleGuide,
  gridItem,
  gridSpacer,
  paperStyle,
  sigCanvas,
  qrCodeWrap
} from './Style'
import __ from '../util'

class MemberCreate extends React.Component {
  constructor (props) {
    super(props)
    this.cx = props.cx
    this.widthType = props.width
    this.reset = {
      group: __.getJsonSto('core') ? __.getJsonSto('core').group : undefined,
      camFaceActive: false,
      faceImageDataUri: undefined,
      faceImageId: undefined,
      signatureImageId: undefined,
      isCreateActive: false,
      tosChecked: false,
      showTos: false,
      residentChecked: false,
      habitualChecked: false,
      medicalChecked: false,
      idMember: undefined,
      emailEmsg: undefined,
      signatureDataUri: undefined,
      email: '',
      firstName: '',
      lastName: '',
      address: '',
      ref: '',
      docType: '',
      birthday: '2000-01-01',
      docNumber: '',
      monthlyG: '',
      isComplete: false,
      sigPadTrimmedDataURL: undefined,
      isLoading: false
    }
    this.state = {
      ...this.reset
    }
    this.handleGoBack = () => {
        this.props.history.push('/dash')
    }
    this.handleTakePhoto = this.handleTakePhoto.bind(this)
    this.handleDocTypeChange = this.handleDocTypeChange.bind(this)
    this.handleCreateMember = this.handleCreateMember.bind(this)
    this.handleConfirmSig = this.handleConfirmSig.bind(this)
    this.sigPad = {}

  }

  async handleTakePhoto (type, imageData) {
    this.setState({
      camFaceActive: false
    })
    const imageId = await this.cx.core.uploadDocument(type, imageData)
    // const imageId = await this.cx.member.uploadDocument(type, imageData)
    this.setState({
      faceImageDataUri: imageData,
      faceImageId: imageId
    })
  }

  handleDocTypeChange(event) {
    this.setState({
      docType: event.target.value
    });
  }

  async handleConfirmSig () {
    const isEmpty = this.sigPad.isEmpty()

    if(isEmpty) {
      this.setState({
        snack: 'Signature is empty'
      })
    } else {
      const signatureImageId = await this.cx.core.uploadDocument('signature', this.sigPad.getTrimmedCanvas().toDataURL())

      // disable pad
      this.sigPad.off()

      this.setState({
        signatureImageId,
        snack: 'Signature confirmed',
        signatureDataUri: this.sigPad.getTrimmedCanvas().toDataURL()
      })
    }
  }

  async handleCreateMember () {
    this.setState({isLoading: true})

    const idMember = await this.cx.core.createMember(
      this.state.email,
      this.state.firstName,
      this.state.lastName,
      this.state.address,
      this.state.ref,
      this.state.birthday,
      this.state.docType,
      this.state.docNumber,
      this.state.monthlyG,
      this.state.residentChecked,
      this.state.habitualChecked,
      this.state.medicalChecked,
      this.state.docImageId,
      this.state.faceImageId,
      this.state.signatureImageId
    )

    this.setState({
      // set modal to show new member id to write down
      idMember,
      isComplete: true,
      isLoading: false
    })
  }

  set (ilk, val) {
    this.setState({ [ilk]: val, emsg: undefined }, () => {
      const d = {}

      d.emailEmsg = __.vldEmail(this.state.email)

      this.setState(d)
    })
  }

  async componentDidMount () {
    // set body bg
    document.body.style.backgroundColor = styleGuide.backgroundDark

    Object.assign(this, __.initView(this, 'memberCreate'))
  }

  render () {
    if (this.state.err) {
      return (
        <ModalCust
          actions={[
            {
              lbl: 'Go to login',
              onClick: () => this.props.history.push('/login')
            }
          ]}
        >
          {this.state.err}
        </ModalCust>
      )
    } else if (this.state.isComplete) {
      return (
        <ModalCust
          noCncl
          lbl={'New member created ✅'}
          actions={this.state.group ? [
            {
              lbl: 'Go to Dashboard',
              onClick: () => this.props.history.push('/dash')
            },
            {
              lbl: 'Create new member',
              onClick: () => window.location.reload()
            },
            {
              lbl: 'Print to PDF',
              onClick: () => {
                const oldTitle = document.title
  
                document.title = `QR Code Member ${this.state.idMember}`
                
                window.print()
  
                document.title = oldTitle
              }
            }
          ] : [
            {
              lbl: 'Create new member',
              onClick: () => window.location.reload()
            },
            {
              lbl: 'Print to PDF',
              onClick: () => {
                const oldTitle = document.title
  
                document.title = `QR Code Member ${this.state.idMember}`
  
                window.print()
  
                document.title = oldTitle
              }
            }
          ]}
        >
          <Typography variant='body1' component='span' gutterBottom>Thank you for registering. You are ready to check-in, please talk to our staff.</Typography><br /><br />
          <Typography variant='body1' component='span'>Your personal member ID:</Typography><br />
          <Typography variant='h4' component='span'>{this.state.idMember}</Typography><br />
          <Typography variant='body1' component='span'>Your QR code:</Typography><br />
          <div className={this.props.classes.qrCodeWrap}>
            <QRCode 
              size={256}
              value={'grdn|'+this.state.idMember} 
              style={{display:'block', width: '100%'}} 
            />
          </div>
          <Typography variant='body1' component='span'>The QR code was also sent to your email address. Keep this safe so that you can easily check in next time or take a screenshot now.</Typography>
        </ModalCust>
      )
    } else if (this.state.showTos) {
      return (
        <Paper elevation={0} square>
          <TopBar
            noUser
            noCart
            noSchedule
            noUpdate
            noBackoffice
            noDash
            noAlert
            noMemberCreate
            noFinance
            iconLeft={<ArrowBack />}
            onClickLeft={(event) => {
              this.setState({
                showTos: false
              })
              window.scrollTo(0, 0)
            }}
            midTitle={'TOS'}
          />
          <Paper className={this.props.classes.paperStyle} square>
            <TosContract
              docNumber={this.state.docNumber}
              lastName={this.state.lastName}
              firstName={this.state.firstName}
              address={this.state.address}
              email={this.state.email}
              birthday={this.state.birthday}
              monthlyG={this.state.monthlyG}
              // residentChecked={this.state.residentChecked}
              signatureDataUri={this.state.signatureDataUri}
              habitualChecked={this.state.habitualChecked}
              medicalChecked={this.state.medicalChecked}
              dateToday={mo().format(__.cfg('tsYearFormat'))}
            />
            <Button onClick={(event) => {
              this.setState({
                showTos: false
              })
              window.scrollTo(0, 0)
            }} style={{marginTop:40}}>
              Back
            </Button>
          </Paper>
        </Paper>
      )
    } else {
      return (
        <div>
          {this.state.snack && (
            <Snack
              msg={this.state.snack}
              onClose={() => this.setState({ snack: null })}
            />
          )}
          {this.state.group && <TopBar
            noUser
            noUpdate
            noAlert
            noMemberCreate
            noDash
            noFinance
            iconLeft={<ArrowBack />}
            onClickLeft={this.handleGoBack}
            midTitle='Create Member'
          />}
          <Paper square>
            <div className={this.props.classes.paperStyle}>
              {!this.state.group && <span>
                <img
                  height='130px'
                  width='130px'
                  src='//cdn.higcsc.com/higlogo-trans.png'
                  title='Logo HIG CSC'
                  alt='Logo HIG CSC'
                  style={{margin: '0 auto', display:'block'}}
                />
                <Typography variant='h4' align='center' style={{margin: '75px 0'}}>Welcome to <nobr>HIG CSC</nobr></Typography>
              </span>}
              <Typography variant='subtitle1'>Please fill new member details</Typography>

              <TextField
                fullWidth
                label='Email'
                margin='normal'
                value={this.state.email}
                inputProps={{ inputMode: 'email' }}
                error={
                  Boolean(this.state.emsg) || Boolean(this.state.emailEmsg)
                }
                helperText={this.state.emsg || this.state.emailEmsg}
                onChange={(event) => {
                  this.set('email', event.target.value)
                }}
              />

              <TextField
                fullWidth
                label='First Name'
                margin='normal'
                inputProps={{ inputMode: 'text' }}
                value={this.state.firstName}
                onChange={(event) => {
                  this.setState({
                    firstName: event.target.value
                  })
                }}
              />

              <TextField
                fullWidth
                label='Last Name'
                margin='normal'
                inputProps={{ inputMode: 'text' }}
                value={this.state.lastName}
                onChange={(event) => {
                  this.setState({
                    lastName: event.target.value
                  })
                }}
              />

              <TextField
                fullWidth
                label='Address'
                margin='normal'
                inputProps={{ inputMode: 'text' }}
                helperText='Street + Number, Floor / Apt, Zip, City, Country'
                value={this.state.address}
                onChange={(event) => {
                  this.setState({
                    address: event.target.value
                  })
                }}
              />

              <TextField
                fullWidth
                id='birthday'
                label='Birthday'
                type='date'
                margin='normal'
                value={this.state.birthday}
                onChange={(event) => {
                  this.setState({
                    birthday: event.target.value
                  })
                }}
              />

              <FormControl margin='normal' fullWidth>
                <InputLabel id='document-type-selector'>Document Type</InputLabel>
                <Select
                  labelId='document-type-selector'
                  id='document-type'
                  value={this.state.docType}
                  label='Document Type'
                  onChange={this.handleDocTypeChange}
                >
                  <MenuItem value={1}>Passport</MenuItem>
                  <MenuItem value={2}>ID-Card</MenuItem>
                  <MenuItem value={3}>Drivers-Licence</MenuItem>
                </Select>
              </FormControl>

              <TextField
                fullWidth
                label='Document Number'
                margin='normal'
                value={this.state.docNumber}
                onChange={(event) => {
                  this.setState({
                    docNumber: event.target.value
                  })
                }}
              />

              <TextField
                fullWidth
                label='Monthly estimate of consumption in gramm'
                margin='normal'
                inputProps={{ inputMode: 'numeric' }}
                value={this.state.monthlyG}
                helperText={'Between 1 and 400'}
                onChange={(event) => {
                  const numberOnly = event.target.value.replace(/\D/g,'')

                  if((Number.isInteger(parseInt(numberOnly)) && parseInt(numberOnly) !== 0 && parseInt(numberOnly) <= 400) || event.target.value === '') {
                    this.setState({
                      monthlyG: numberOnly
                    })
                  }
                }}
              />

              <TextField
                fullWidth
                label='Referred by'
                margin='normal'
                helperText='The name of a existing member referred you'
                value={this.state.ref}
                onChange={(event) => {
                  this.setState({
                    ref: event.target.value
                  })
                }}
              />

              <Typography variant='h5' style={{marginTop:40}} gutterBottom>
                Face Image
              </Typography>
              {!this.state.camFaceActive && <Button
                fullWidth
                variant='outlined'
                color='primary'
                disabled={this.state.email.length < 3 || this.state.firstName.length < 3 || this.state.lastName.length < 3 || this.state.address.length < 3 || this.state.docNumber.length < 3 || this.state.emailEmsg.length > 0 || this.state.docType === '' || this.state.monthlyG === '' }
                onClick={() => {
                  this.setState({camFaceActive: true})
                }}
              >
                Start Cam
              </Button>}
              {this.state.camFaceActive && <div>
                <Camera
                  onTakePhoto={(faceImageDataUri) => { this.handleTakePhoto('face', faceImageDataUri) }}
                  idealFacingMode='user'
                  idealResolution={{width: 640, height: 480}}
                  // isImageMirror={false}
                  imageType='jpg'
                  imageCompression={0.90}
                />
              </div>}
              {this.state.faceImageDataUri && this.state.faceImageDataUri.length > 0 && <Box style={{textAlign: 'center', marginTop: 8}}><img alt='img' style={{ maxWidth: '100%', maxHeight: '420px' }} src={this.state.faceImageDataUri} /></Box>}

              <Typography variant='h5' style={{marginTop:40}} gutterBottom>
                Signature
              </Typography>
              <Box style={{textAlign: 'center', position: 'relative'}}>
                {!this.state.signatureDataUri && <SignatureCanvas penColor='green' canvasProps={{className: this.props.classes.sigCanvas}} ref={(ref) => { this.sigPad = ref }} />}
                {this.state.signatureDataUri && <img alt='signature' height='150px' width='318px' src={this.state.signatureDataUri} style={{maxWidth:'100%', maxHeight:'185px', marginTop: 18, marginBottom: 18}}/>}
                <Button
                  onClick={() => {
                    this.sigPad.clear()
                  }}
                  disabled={this.state.signatureImageId !== undefined}
                  style={{position:'absolute', top: 0, right: 0}}
                >
                  Clear
                </Button>
                <Button
                  fullWidth
                  variant='outlined'
                  color='primary'
                  onClick={this.handleConfirmSig}
                  disabled={this.state.signatureImageId !== undefined}
                  style={{marginTop: 8}}
                >
                  Confirm Signature
                </Button>
              </Box>


              <Typography variant='h5' style={{marginTop:40}} gutterBottom>
                Member Questions
              </Typography>
              <FormControl component='fieldset'>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox name='residentChecked' checked={this.state.residentChecked} onChange={(event) => {
                      this.setState({
                        residentChecked: event.target.checked
                      })
                    }} />}
                    label='I am a resident in Spain'
                  />
                  <Typography variant='body2' style={{marginTop: 40}} gutterBottom>
                    Choose one
                  </Typography>
                  <FormControlLabel
                    control={<Checkbox name='habitualChecked' checked={this.state.habitualChecked} disabled={this.state.medicalChecked} onChange={(event) => {
                      this.setState({
                        habitualChecked: event.target.checked,
                        medicalChecked: false
                      })
                    }} />}
                    label='I am a habitual cannabis user'
                  />
                  <FormControlLabel
                    control={<Checkbox name='medicalChecked' checked={this.state.medicalChecked} disabled={this.state.habitualChecked} onChange={(event) => {
                      this.setState({
                        habitualChecked: false,
                        medicalChecked: event.target.checked
                      })
                    }} />}
                    label='I am a medical cannabis patient'
                  />
                </FormGroup>
              </FormControl>

              <Typography variant='h5' style={{marginTop:40}} gutterBottom>
                Terms & Conditions
              </Typography>
              <Button
                fullWidth
                variant='outlined'
                color='primary'
                onClick={(event) => {
                  this.setState({
                    showTos: true
                  })
                  window.scrollTo(0, 0)
                }}
                style={{marginBottom: 8}}
              >Show TOS (AS. MILENA VERDA - Solicitud de ingreso de socio)</Button>
              <FormControl component='fieldset'>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox name='tosChecked' checked={this.state.tosChecked} disabled={this.state.signatureImageId === undefined || (!this.state.habitualChecked && !this.state.medicalChecked)} onChange={(event) => {
                      this.setState({
                        tosChecked: event.target.checked
                      })
                    }} />}
                    label='I have read and I accept the terms and conditions. I want to become a member.'
                  />
                </FormGroup>
              </FormControl>
              <Button
                fullWidth
                variant='contained'
                color='primary'
                disabled={!this.state.tosChecked || this.state.signatureImageId === undefined || this.state.isLoading}
                onClick={this.handleCreateMember}
                style={{marginTop: 40}}
              >
                Create Member
              </Button>
            </div>
          </Paper>
        </div>
      )
    }
  }
}

export default
  withStyles({
    gridItem,
    gridSpacer,
    paperStyle,
    sigCanvas,
    qrCodeWrap
  })(MemberCreate)
