import React from 'react'
import {
  Grid,
  Paper,
  Button,
  Typography
} from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { withStyles } from '@mui/styles'
import { TopBar } from './Lib'
import {
  styleGuide,
  paperStyle
} from './Style'
import __ from '../util'

class Alert extends React.Component {
  constructor (props) {
    super(props)
    this.cx = props.cx
    this.widthType = props.width
    this.state = {}
    this.activateAlert = this.activateAlert.bind(this)
    this.handleGoBack = () => {
        this.props.history.push('/dash')
    }
  }

  async activateAlert () {
    try {
      await this.cx.dash.createAlert(__.getJsonSto('core').loginemail)
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }
    this.props.history.push('/dash')
  }

  async componentDidMount () {
    // set body bg
    document.body.style.backgroundColor = styleGuide.backgroundDark

    Object.assign(this, __.initView(this, 'alert'))
  }

  render () {
    return (
      <div>
        <TopBar
          noUser
          noAlert
          noUpdate
          noCart
          noSchedule
          noBackoffice
          noMemberCreate
          noFinance
          noDash
          iconLeft={<ArrowBack />}
          onClickLeft={this.handleGoBack}
          midTitle='Pause shift'
        />
        <Paper elevation={0} square>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={3}>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper square className={this.props.classes.paperStyle} elevation={24}>
                <Typography variant='h4' align='center' style={{marginTop: 30, marginBottom: 15}}>
                  Coffee break?
                </Typography>
                <Button
                  fullWidth
                  variant='outlined'
                  size='large'
                  onClick={async event => this.activateAlert(event)}
                  style={{marginTop: 16, marginBottom: 8}}
                >
                  ♨️ YES, I am sure! ♨️
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </div>
    )
  }
}

export default
  withStyles({
    paperStyle
  })(Alert)
