import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { theme } from './view/Style';
import Core from './logic/Core';
import DashView from './view/Dash';
import AlertView from './view/Alert';
import FinanceView from './view/Finance';
import CartView from './view/Cart';
import LoginView from './view/Login';
import UserView from './view/User';
import MemberCreateView from './view/MemberCreate';
import MenuView from './view/Menu';
import MenuScreen1 from './view/MenuScreen1';
import MenuScreen2 from './view/MenuScreen2';

const cx = {};
cx.core = new Core(cx);

const login = (props) => {
  props.cx = cx;
  return cx.core.init() ? <Redirect to="/dash" /> : <LoginView {...props} />;
};

const memberCreate = (props) => {
  props.cx = cx;

  return <MemberCreateView {...props} />;
};

const menu = (props) => {
  props.cx = cx;

  return <MenuView {...props} />;
};

const menuScreen1 = (props) => {
  props.cx = cx;

  return <MenuScreen1 {...props} />;
};

const menuScreen2 = (props) => {
  props.cx = cx;

  return <MenuScreen2 {...props} />;
};

const AuthRoute = ({ component: Component, ...args }) => (
  <Route
    {...args}
    render={(props) => {
      props.cx = cx;
      return cx.core.init() ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      );
    }}
  />
);

const Routes = () => (
  <MuiThemeProvider theme={theme}>
    <BrowserRouter>
      <Switch>
        <Route path="/login" exact render={login} />
        <Route path="/member/create" exact render={memberCreate} />
        
        <Route path="/menu" exact render={menu} />
        <Route path="/menu/1" exact render={menuScreen1} />
        <Route path="/menu/2" exact render={menuScreen2} />

        <AuthRoute path="/dash" exact component={DashView} />
        <AuthRoute path="/cart" exact component={CartView} />
        <AuthRoute path="/coffeebreak" exact component={AlertView} />
        <AuthRoute path="/finance" exact component={FinanceView} />

        <AuthRoute path="/user" exact component={UserView} />

        <Redirect to="/dash" />
      </Switch>
    </BrowserRouter>
  </MuiThemeProvider>
);

const root = createRoot(document.getElementById('root'));
root.render(<Routes />);

serviceWorkerRegistration.register();
